export const styles = {
  mainContainer: {
    // TODO: check in which cases it causes scrollbar and handle those particular ones
    // Setting this generally breaks cases where the scrollbar really needs to be applied
    // '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    //   display: 'none'
    // },
    '& *': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    label: 'homeMainContainer',
    minWidth: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'flex-start',
    flexDirection: ['column', 'column', 'column', 'column', 'row', 'row'],
    justifyContent: [
      'flex-start',
      'flex-start',
      'flex-start',
      'flex-start',
      'normal',
      'normal'
    ],
    width: '100%',
    maxHeight: '100%'
  },
  sophieVideo: {
    objectFit: 'cover',
    width: '100vw',
    height: [
      'calc(100vh - 48px)',
      'calc(100vh - 48px)',
      '100vh',
      '100vh',
      '100vh',
      '100vh'
    ],
    minHeight: ['auto', 'auto', '100vh', '100vh', '100vh'],
    position: ['static', 'static', 'static', 'static', 'fixed', 'fixed'],
    top: 0,
    left: 0,
    zIndex: 0
  },
  sophieVideoWidget: {
    objectFit: 'cover',
    width: ['100vw', '100vw', '100vw', '100vw', '100%', '100%'],
    height: ['100vh', '100vh', '100vh', '100vh', 'unset', 'unset'],
    position: ['fixed', 'fixed', 'fixed', 'fixed', 'static', 'static'],
    mb: [0, 0, 0, 0, 2, 2],
    borderRadius: ['unset', 'unset', 'unset', 'unset', 'card', 'card'],
    top: 0,
    left: 0
  },
  videoContainer: {
    my: 2,
    width: '28%',
    display: ['none', 'none', 'none', 'none', 'block', 'block']
  },
  title: {
    fontSize: ['16px', '16px', '24px', '24px', '24px', '24px'],
    lineHeight: ['16px', '16px', '32px', '32px', '32px', '32px'],
    letterSpacing: '-0.01em'
  },
  subtitle: {
    fontSize: ['12px', '12px', '16px', '16px', '16px', '16px'],
    lineHeight: ['16px', '16px', '24px', '24px', '24px', '24px'],
    letterSpacing: '-0.01em',
    opacity: 0.6
  },
  letsChatButton: {
    cursor: 'pointer'
  },
  actions: {
    label: 'Home-actions',
    flexDirection: 'column'
  },
  mainActions: {
    label: 'Home-mainActions',
    flexDirection: ['column', 'column', 'column', 'column', 'row', 'row'],
    mt: 12,
    '& > *:not(:last-child)': {
      mr: [0, 0, 0, 0, 6, 6],
      mb: [2, 2, 2, 2, 0, 0]
    }
  },
  backButton: {
    label: 'Home-actions',
    mt: 12
  },
  actionsWithBackButton: {
    label: 'actionsWithBackButton',
    flexDirection: 'column',
    '& > *': {
      flex: 1
    },
    '& > *:not(:last-child)': {
      mb: 2
    }
  },
  privacyPolicyLink: {
    textDecoration: 'underline',
    color: 'link',
    display: 'inline-flex',
    cursor: 'pointer',
    '&:focus': {
      border: 'focusOutline',
      padding: '3px 0',
      borderRadius: 'button'
    }
  },
  titleContainer: {
    display: 'flex',
    width: '100%'
  },
  acceptTerms: {
    mt: [4, 4, 4, 4, 12],
    mb: [0, 0, 0, 0, 6],
    alignItems: 'center'
  }
}
export default styles
